import { useEffect, useState, useRef } from "react";
import { DatePicker, DateValue } from "@mantine/dates";
import { Button, TextInput, useMantineTheme, Text } from "@mantine/core";
import "@mantine/dates/styles.css";
import "./CalenderComponent.css";
import { format } from "date-fns";
import { getDaysDifferenceFromToday } from "../../utils/calendarUtils";

type Props = {
	onSelect: (date: Date | null) => void;
	minDate?: Date;
	maxDate?: Date | undefined;
	placeholder: string;
	style?: React.CSSProperties;
	decrementalCalendar?: boolean;
	defaultDateValue?: Date | null;
	isOpen?: boolean;
	onToggle?: () => void;
	disableWeekends?: boolean;
};

const DATE_SPEC_TODAY = "DATE_SPEC_TODAY";
const DATE_SPEC_YESTERDAY = "DATE_SPEC_YESTERDAY";
const DATE_SPEC_TOMORROW = "DATE_SPEC_TOMORROW";

const weekArray = Array.from({ length: 7 }, (_, index) => index);

function isWeekend(date: any) {
	const day = date.getDay();
	return day === 0 || day === 6;
}

export const CalendarComponent = ({
	onSelect,
	placeholder,
	style = {},
	decrementalCalendar,
	maxDate,
	minDate,
	defaultDateValue,
	isOpen,
	onToggle,
	disableWeekends,
}: Props) => {
	const theme = useMantineTheme();
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
	const [showCalendar, setShowCalendar] = useState(false);
	const [specialDate, setSpecialDate] = useState<string | null>(null);
	const calendarRef = useRef<HTMLDivElement>(null); 

	const isCalendarVisible = isOpen !== undefined ? isOpen : showCalendar;

	const isDateOutsideRange = (dayIndex: number) => {
		const daysDiff = getDaysDifferenceFromToday(minDate ? minDate as Date : maxDate as Date);

		if (minDate && daysDiff as number > 0) return true
		else if (maxDate && daysDiff as number > 0 ) return false;
		const absoluteDaysDiff = Math.abs(daysDiff as number)
		if (!absoluteDaysDiff || absoluteDaysDiff > 6) return false;
		return minDate ? dayIndex > absoluteDaysDiff : dayIndex < absoluteDaysDiff;
	}

	useEffect(() => {
		selectedDate && onSelect(selectedDate);
	}, [selectedDate]);

	useEffect(() => {
		if (defaultDateValue) handleCalendarSelect(defaultDateValue);
	}, [defaultDateValue]);

	useEffect(() => {
		if (specialDate === DATE_SPEC_TODAY) {
			setSelectedDate(new Date());
		} else if (specialDate === DATE_SPEC_YESTERDAY) {
			const yesterday = new Date();
			yesterday.setDate(yesterday.getDate() - 1);
			setSelectedDate(yesterday);
		} else if (specialDate === DATE_SPEC_TOMORROW) {
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			setSelectedDate(tomorrow);
		}
	}, [specialDate]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
				if (isCalendarVisible) {
					setShowCalendar(false);
					onToggle?.();
				}
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isCalendarVisible]);

	const handleCalendarSelect = (date: DateValue | string | null) => {
		if (disableWeekends && isWeekend(date)) {
			return; 
		}

		if (date === null) {
			setSelectedButtonIndex(0);
			setSelectedDate(null);
			onToggle?.();
			setShowCalendar(false);
			return;
		}

		if (typeof date === "string") {
			setSpecialDate(date);
			setSelectedDate(null);
			onToggle?.();
			setShowCalendar(false);
			return;
		}

		const today = new Date();
		today.setHours(0, 0, 0, 0);
		date.setHours(0, 0, 0, 0);
		const diffTime = date.getTime() - today.getTime();
		let diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
		if (decrementalCalendar) diffDays *= -1;

		setSelectedButtonIndex(diffDays);
		setSpecialDate(null);
		setSelectedDate(date as Date);
		onToggle?.();
		setShowCalendar(false);
	};

	const formatDateLabel = (days: number) => {
		if (decrementalCalendar) days = days * -1;
		if (days === 0) return "Today";
		else if (days === 1) return "Tomorrow";
		else if (days === -1) return "Yesterday";
		const date = new Date();
		date.setDate(date.getDate() + days);

		const formattedDate = format(date, "E, MMM dd");
		return `${formattedDate} (${decrementalCalendar ? "" : "+"}${days}d)`;
	};

	const handleDateButtonClick = (days: number) => {
		if (decrementalCalendar) days *= -1;
		switch (days) {
			case 0:
				setSelectedButtonIndex(0);
				handleCalendarSelect(DATE_SPEC_TODAY);
				break;
			case 1:
				setSelectedButtonIndex(1);
				handleCalendarSelect(DATE_SPEC_TOMORROW);
				break;
			case -1:
				setSelectedButtonIndex(1);
				handleCalendarSelect(DATE_SPEC_YESTERDAY);
				break;
			default:
				const clickedDate = new Date();
				clickedDate.setHours(0, 0, 0, 0);
				clickedDate.setDate(clickedDate.getDate() + days);
				handleCalendarSelect(clickedDate);
				break;
		}
	};

	const renderDay = (date: Date) => {
		const selectedColor = {
			backgroundColor: theme.colors.grey[3],
			color: theme.colors.dark[9],
		};

		const isWeekendDay = disableWeekends && isWeekend(date);

		return (
			<div
				className='selectedicon'
				style={
					selectedDate && date.toDateString() === selectedDate.toDateString()
						? selectedColor
						: undefined
				}
				onClick={() => {
					if (!isWeekendDay) {
						setSelectedDate(date);
					}
				}}
			>
				{date.getDate()}
			</div>
		);
	};

	return (
		<div
			className='calender-container'
			style={{
				display: "flex",
				flexDirection: "column",
				cursor: "pointer",
				...style,
			}}
			ref={calendarRef}
		>
			<TextInput
				className='calender-input'
				onClick={() => (onToggle ? onToggle() : setShowCalendar(!showCalendar))}
				value={
					selectedDate
						? Math.abs(selectedButtonIndex) < 2
							? formatDateLabel(selectedButtonIndex)
							: selectedDate.toDateString()
						: ""
				}
				style={style}
			/>

			{isCalendarVisible && (
				<div className='calender' style={style ? style : {}}>
					<DatePicker
						className='date-picker'
						highlightToday={true}
						firstDayOfWeek={0}
						onChange={(value) => {
							handleCalendarSelect(value);
						}}
						weekdayFormat={(date) => format(date, "EEE").toUpperCase()}
						renderDay={renderDay}
						maxDate={maxDate}
						minDate={minDate}
					/>

					<div className='buttons'>
						{weekArray.map((dayIndex) => (
							<Button
								key={dayIndex}
								onClick={() => handleDateButtonClick(dayIndex)}
								className={`date-button${selectedButtonIndex === dayIndex ? " active" : ""}`}
								h={34}
								size='lg'
								disabled={isDateOutsideRange(dayIndex)}
								opacity={isDateOutsideRange(dayIndex)? 0.5 : 1}
							>
								<Text size='0.9375rem' fw='500'>
									{formatDateLabel(dayIndex)}
								</Text>
							</Button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
