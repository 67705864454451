import React, { useEffect, useRef, useState } from "react";
import { Container, Paper, useMantineTheme, Box } from "@mantine/core";
import { useLocation, useSearchParams } from "react-router-dom";
import "@mantine/core/styles.css";
import "./Leftnav.css";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchFundManagerCounters, updateState } from "../../store/marketSlice";
import { useInterval } from "../../utils/hooks/useInterval";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import IconWrapper from "../Common/IconWrapper";
import { LABELS_WITH_DETAILS } from "../Common/Labels/labelDefinitions";
import { CountsType, SECTIONS_DATA } from "./sectionData";
import SidebarItem from "./SidebarItem";
import { formatLabelsNavbar } from "../../utils/conversions";
import SectionHeader from "./SectionHeader";
import { allFundsCounter } from "../../store/fundStatusSlice";

const LeftNavbar: React.FC = () => {
	const theme = useMantineTheme();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	const [activeItem, setActiveItem] = useState<{ section: string; id: string | null }>({
		section: "",
		id: null,
	});

	const { user } = useAppSelector((state) => state.user);
	const { counters } = useAppSelector((state) => state.market);
	const dispatch = useAppDispatch();
	const workItemsCount = useSelector((state: RootState) => state.market.workItemsCount);
	const paperRef = useRef<HTMLDivElement>(null);
	const location = useLocation();

	const counts: CountsType = {
		marketOnOpenCount: counters?.market_on_open_counters?.total_funds_in_aggregate_count || 0,
		marketOnOpenCountReady: counters?.market_on_open_counters?.ready_state_counts?.total_funds || 0,
		marketOnOpenCountNotReady:
			counters?.market_on_open_counters?.not_ready_state_counts?.total_funds || 0,
		marketOnOpenCountCompleted:
			counters?.market_on_open_counters?.completed_state_counts?.total_funds || 0,
		marketCount: counters?.market_counters?.total_funds_in_aggregate_count || 0,
		marketCountReady: counters?.market_counters?.ready_state_counts?.total_funds || 0,
		marketCountNotReady: counters?.market_counters?.not_ready_state_counts?.total_funds || 0,
		marketCountCompleted: counters?.market_counters?.completed_state_counts?.total_funds || 0,
		marketOnCloseCount: counters?.market_on_close_counters?.total_funds_in_aggregate_count || 0,
		marketOnCloseCountReady:
			counters?.market_on_close_counters?.ready_state_counts?.total_funds || 0,
		marketOnCloseCountNotReady:
			counters?.market_on_close_counters?.not_ready_state_counts?.total_funds || 0,
		marketOnCloseCountCompleted:
			counters?.market_on_close_counters?.completed_state_counts?.total_funds || 0,
		marketOnClosePlusDaysCount:
			counters?.market_on_close_plus_days_counters?.total_funds_in_aggregate_count || 0,
		marketOnClosePlusDaysCountReady:
			counters?.market_on_close_plus_days_counters?.ready_state_counts?.total_funds || 0,
		marketOnClosePlusDaysCountNotReady:
			counters?.market_on_close_plus_days_counters?.not_ready_state_counts?.total_funds || 0,
		marketOnClosePlusDaysCountCompleted:
			counters?.market_on_close_plus_days_counters?.completed_state_counts?.total_funds || 0,
	};

	const navItems = [
		{ icon: "dashboard", label: t("MY_DASHBOARD"), count: workItemsCount, path: routes.HOME },
		{ icon: "AllFunds", label: t("ALL_FUNDS"), path: routes.ALLFUNDSTATUS },
		{ icon: "AskAnythingIcon", label: t("AI_ASSISTANT"), path: routes.AIASSISTANT },
		{ icon: "SmartSearchIcon", label: t("ALL_FUND_QUERY"), path: routes.ALLFUNDQUERY },
		{ icon: "coac", label: t("COAC_MANAGEMENT"), path: routes.COACMANAGEMENT },
		{ icon: "settingicon", label: t("SETTINGS"), path: routes.ACCOUNT },
	];

	useEffect(() => {
		dispatch(updateState({ key: "loading", value: true }));
		dispatch(fetchFundManagerCounters(user ? user.username : ""));
		dispatch(allFundsCounter())
	}, [pathname, searchParams, user, dispatch]);

	useInterval({
		callback: () => dispatch(fetchFundManagerCounters(user ? user.username : "")),
		type: "thunk",
	});

	const isItemSelected = (path: string, sectionId?: string | null) => {
		if (location.pathname === path) {
			if (sectionId) {
				return searchParams.get("selected") === sectionId;
			}
			return true;
		}
		return false;
	};

	const getLabelsWithIcons = (countKeyPrefix: keyof CountsType) =>
		Object.values(LABELS_WITH_DETAILS).map((details) => {
			const dynamicKey = `${countKeyPrefix}${formatLabelsNavbar(details.id)}` as keyof CountsType;

			const count = counts[dynamicKey] || 0;

			return {
				id: details.id,
				label: t(details.labelKey),
				count,
				icon: details.icon,
			};
		});

	return (
		<Container className='nav-container'>
			<Paper shadow='sm' bg={theme.colors.grey[0]} className={"paper"} ref={paperRef}>
				<Box className={"left-nav-header"}>
					<IconWrapper name='Logo-long' className='nav-logo' alt='atlantis-logo' />
				</Box>

				<div className='scroll-bar'>
					<div className={"tables-nav gap nav-items"}>
						{navItems.map(({ icon, label, count, path }, index) => (
							<SidebarItem
								key={index}
								icon={icon}
								label={label}
								count={count}
								path={path}
								isSelected={isItemSelected(path)}
								onClick={() => setActiveItem({ section: "nav", id: path })}
								isNavItem={true}
							/>
						))}
					</div>

					<div className={"tables-nav order-gap"}>
						{SECTIONS_DATA.map((section) => {
							const shouldRender = section.showCondition(counts);

							const sectionLabels = getLabelsWithIcons(section.sectionKey as keyof CountsType);

							return (
								shouldRender && (
									<div key={section.sectionKey} className='section'>
										<div className='section-header-link'>
											<SectionHeader
												title={t(section.titleKey)}
												count={counts[section.sectionKey as keyof CountsType]}
												hideCount={section.sectionKey === "marketOnClosePlusDaysCount"}
											/>
										</div>

										{sectionLabels.map((label) => {
											const shouldHideCount =
												section.sectionKey === "marketOnClosePlusDaysCount" &&
												["Not Ready", "Completed"].includes(label.label);

											return (
												<SidebarItem
													key={label.id}
													icon={typeof label.icon === "string" ? label.icon : ""}
													label={label.label}
													count={shouldHideCount ? undefined : label.count}
													path={`${section.path}?selected=${label.id}`}
													isSelected={isItemSelected(section.path, label.id)}
													onClick={() =>
														setActiveItem({ section: section.sectionKey, id: label.id })
													}
												/>
											);
										})}
									</div>
								)
							);
						})}
					</div>
				</div>
			</Paper>
		</Container>
	);
};

export default LeftNavbar;
