import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCurrentUTCDate } from "../utils/dateUtils";
import axios from "axios";
import { services } from "../services";

interface FundStatusState {
	selectedMarketState: string;
	fundsOrdersTab: "FUNDS" | "ORDERS";
	allFundsMarketOnOpenCount: number;
	allFundsMarketOnCloseCount: number;
}

const initialState: FundStatusState = {
	selectedMarketState: "MARKET_ON_OPEN",
	fundsOrdersTab: "FUNDS",
	allFundsMarketOnOpenCount: 0,
	allFundsMarketOnCloseCount: 0,
};

export const allFundsCounter = createAsyncThunk("allFundsCounter", async (_, { dispatch }) => {
	const queryParamsForOpenMarket = {
		market_state: "MARKET_ON_OPEN",
		all_funds_flag: "true", // string format as per the url req
		target_date: getCurrentUTCDate(),
		calc_date: getCurrentUTCDate(),
	};
	// get counts for all funds in market open condition
	try {
		const allFundsMarketOnOpen = await axios.get(
			`${services.FUND_STATE}?${new URLSearchParams(queryParamsForOpenMarket)}`,
		);
		const fundsCountMarketOnOpen = allFundsMarketOnOpen.data.data.length;
		dispatch(updateAllFundMarketOpenCount({ count: fundsCountMarketOnOpen ?? 0 }));
	} catch (error) {
		console.log("error fetching all funds : ", error);
	}

	// get counts for all funds in market close condition
	const queryParamsForCloseMarket = {
		...queryParamsForOpenMarket,
		market_state: "MARKET_ON_CLOSE",
	};
	try {
		const allFundsMarketOnClose = await axios.get(
			`${services.FUND_STATE}?${new URLSearchParams(queryParamsForCloseMarket)}`,
		);
		const fundsCountMarketOnClose = allFundsMarketOnClose.data.data.length;
		dispatch(updateAllFundMarketCloseCount({ count: fundsCountMarketOnClose ?? 0 }));
	} catch (error) {
		console.log("error fetching all funds : ", error);
	}
});

const fundStatusSlice = createSlice({
	name: "fundStatus",
	initialState,
	reducers: {
		setMarketState(
			state,
			action: PayloadAction<{ marketState: string; }>,
		) {
			return {
				...state,
				selectedMarketState: action.payload.marketState,
			};
		},
		setFundsOrdersTab(state, action: PayloadAction<"FUNDS" | "ORDERS">) {
			return {
				...state,
				fundsOrdersTab : action.payload
			}
		},
		updateAllFundMarketOpenCount(state, action) {
			return {
				...state,
				allFundsMarketOnOpenCount: action.payload.count,
			};
		},
		updateAllFundMarketCloseCount(state, action) {
			return {
				...state,
				allFundsMarketOnCloseCount: action.payload.count,
			};
		},
	},
});

export const {
	setMarketState,
	setFundsOrdersTab,
	updateAllFundMarketOpenCount,
	updateAllFundMarketCloseCount,
} = fundStatusSlice.actions;
export default fundStatusSlice.reducer;
