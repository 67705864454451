import React from "react";
import { CalendarComponent } from "../CalendarComponent";

interface DateSelectorProps {
	label: string;
	selectedDate: Date | null;
	onSelect: (date: Date | null) => void;
	placeholder?: string;
	minDate?: Date;
	maxDate?: Date;
}

const DateSelector: React.FC<DateSelectorProps> = ({
	label,
	selectedDate,
	onSelect,
	placeholder,
	minDate,
	maxDate
}) => {
	return (
		<div>
			<CalendarComponent
				onSelect={(date) => onSelect(date)} 
				defaultDateValue={selectedDate}
				placeholder={placeholder || `Select ${label}`}
				decrementalCalendar={true}
				maxDate={maxDate}
				minDate={minDate}
			/>
		</div>
	);
};

export default DateSelector;
