import axios from "axios";
import { services } from "../../../services";
import { format, isToday, isYesterday, isTomorrow, isValid } from 'date-fns';
import { DATE_SPEC_TODAY, DATE_SPEC_TOMORROW, DATE_SPEC_YESTERDAY, getSpecialDate } from '../../../constants';
import { v4 as uuid_v4 } from "uuid";
import { Document } from '../../../types';

const mapData = (data: any[] | undefined, endpoint: string) => {
  console.log("Mapping data for endpoint:", endpoint);

  if (!Array.isArray(data)) {
    console.error("Invalid data format. Expected an array.");
    return [];
  }

  // Initialize an array to hold the mapped items
  const mappedItems: any[] = [];

  // Function to map items for the asset endpoint
  const mapAssetItem = (item: any, index: number) => {
    const asset = item.asset;
    const assetQuant = item.asset_quantities
    if (!asset || !assetQuant) {
      console.warn("Item missing asset", item);
      return null;
    }
    const { total_quantity, traded_quantity, settled_quantity, substituted_in_quantity, substituted_out_quantity, adjusted_quantity, indirectly_held_quantity, on_loan_quantity, approved_orders_quantity, proposed_orders_quantity } = assetQuant.equity;

    return {
      sr_no: index + 1,
      fund_id: asset.fund_id,
      as_of_date: asset.calc_date,
      asset_id: asset.asset_key.asset_id,
      bloomberg_ticker: asset.asset_key.bloomberg_ticker,
      currency: asset.asset_attributes.currency,
      price_date: asset.asset_prices.price_date,
      price: asset.asset_prices.prices_for_price_type?.close_price,
      average_daily_volume: asset.asset_prices.average_daily_volume,
      min_trade_size: asset.asset_prices.min_trade_size,
      min_trade_increment: asset.asset_prices.min_trade_increment,
      source: asset.asset_prices.source,
      asset_group: asset.asset_group,
      asset_name: asset.asset_attributes.asset_name,
      issue_country: asset.asset_attributes.issue_country,
      trading_country: asset.asset_attributes.trading_country,
      risk_country: asset.asset_attributes.risk_country,
      sector: asset.asset_attributes.sector,

      total_quantity,
      traded_quantity,
      settled_quantity,
      substituted_in_quantity,
      substituted_out_quantity,
      adjusted_quantity,
      indirectly_held_quantity,
      on_loan_quantity,
      approved_orders_quantity,
      proposed_orders_quantity
    };
  };

  // Function to map items for the orders endpoint
  const mapOrderItem = (item: any) => {
    const order = item.orders;
    return {
      fund_id: order.fund_id,
      asset_id: order.asset_key.asset_id,
      bloomberg_ticker: order.asset_key.bloomberg_ticker,
      asset_type: order.asset_type,
      market: order.market,
      asset_name: order.asset_name,
      transaction_type: order.transaction_type,
      quantity: order.quantity,
      currency: order.currency,
      amount: order.amount,
      order_motivation: order.order_motivation,
      trading_benchmark: order.trading_benchmark,
      benchmark_date: order.benchmark_date,
      trade_date: order.trade_date,
      settle_date_offset: order.settle_date_offset,
      broker_identifier: order.broker_identifier,
      execution_instruction: order.execution_instruction,
      limit_type: order.limit_type,
      limit_value: order.limit_value,
      stop_type: order.stop_type,
      stop_value: order.stop_value,
      filled_quantity: order.filled_quantity,
      filled_amount: order.filled_amount,
      settlement_currency: order.settlement_currency,
      price_currency: order.price_currency,
      basket_name: order.basket_name,
      comments: order.comments,
      order_status: order.order_status,
      create_time: order.create_time,
      created_by: order.created_by,
      last_updated_time: order.last_updated_time,
      last_updated_by: order.last_updated_by,
      transaction_id: order.transaction_id,
      order_type: order.order_type,
    };
  };

  // Function to map items for the benchmark endpoint
  const mapBenchmarkItem = (item: any) => {
    const benchmarkChange = item.benchmark_change;
    return {
      fund_id: item.fund_id,
      benchmark_id: item.benchmark_id,
      fund_currency: item.fund_currency,
      fund_group: item.fund_group,
      fund_name: item.fund_name,
      primary_fund_manager: item.primary_fund_manager,
      benchmark_identifier: benchmarkChange.benchmark_identifier,
      change_type: benchmarkChange.change_type,
      share_change: benchmarkChange.share_change,
      price: benchmarkChange.price,
      currency: benchmarkChange.currency,
      market_cap_change: benchmarkChange.market_cap_change,
      pct_change: benchmarkChange.pct_change,
      description: benchmarkChange.description,
      change_id: benchmarkChange.change_id,
      source_id: benchmarkChange.source_id,
      vendor_id: benchmarkChange.vendor_id,
      effective_date: benchmarkChange.effective_date,
      asset_id: benchmarkChange.asset_key.asset_id,
      bloomberg_ticker: benchmarkChange.asset_key.bloomberg_ticker,
    };
  };

  // Function to map items for the cashflow endpoint
  const mapCashFlowItem = (item: any) => {
    const cashFlow = item.cash_flow;
    return {
      fund_id: cashFlow.fund_id,
      fund_name: item.fund_name,
      trade_date: cashFlow.trade_date,
      settle_date: cashFlow.settle_date,
      currency: cashFlow.currency,
      amount: cashFlow.amount,
      amount_in_fund_currency: cashFlow.amount_in_fund_currency,
      description: cashFlow.description,
      status: cashFlow.status,
      is_confirmed: cashFlow.is_confirmed,
      is_estimated: cashFlow.is_estimated,
      is_unit_order: cashFlow.is_unit_order,
      num_of_units: cashFlow.num_of_units,
      unit_price: cashFlow.unit_price,
      unit_price_date: cashFlow.unit_price_date,
      unit_price_source: cashFlow.unit_price_source,
      unit_holder_id: cashFlow.unit_holder_id,
      client_id: cashFlow.client_id,
      transaction_id: cashFlow.cash_flow_transaction_id,
      create_date: cashFlow.create_date,
      created_by: cashFlow.created_by,
      modify_date: cashFlow.modify_date,
      modified_by: cashFlow.modified_by,
      authorize_date: cashFlow.authorize_date,
      authorized_by: cashFlow.authorized_by,
      cancel_requested_by: cashFlow.cancel_requested_by,
      cancel_approved_by: cashFlow.cancel_approved_by,
    };
  };

  // Iterate over data and map based on endpoint
  data.forEach((item, index) => {
    let mappedItem;

    if (endpoint === services.ASSETS) {
      mappedItem = mapAssetItem(item, index);
    } else if (endpoint === services.ORDERS) {
      mappedItem = mapOrderItem(item);
    } else if (endpoint === services.BENCHMARK) {
      mappedItem = mapBenchmarkItem(item);
    } else if (endpoint === services.CASHFLOW) {
      mappedItem = mapCashFlowItem(item);
    }

    if (mappedItem) {
      mappedItems.push(mappedItem);
    }
  });

  return mappedItems;
};



const mapDatas = (data: any[], endpoint: string) => {
  const mappedData: any[] = [];

  data.forEach((item: any) => {
    const { fund_id, fund_name, targets } = item;

    if (targets && Array.isArray(targets)) {
      targets.forEach((target: any, index: number) => {
        console.log("Checking target:", target);

        const mappedItem: any = {
          sr_no: mappedData.length + 1,
          fund_name,
          fund_id,
          type: target.type || null,
          target_category: target.target_category || null,
          lower_threshold: target.lower_threshold || null,
          upper_threshold: target.upper_threshold || null,
          target_value: target.hasOwnProperty("target_value") ? target.target_value : null,
        };

        console.log("Mapped Item:", mappedItem);

        mappedData.push(mappedItem);
      });
    }
  });

  return mappedData;
};

const savePreset = async (folderId: string | null = null, uuid: string, {
  presetName, selectedFolderName,
  fundGroup, fundId,
  assetId, assetIdType, selectedFromDate, selectedToDate, settled,
  onLoan, total, selectedTypeOfData
}: any) => {

  const buildFormData = () => {
    const formData: any = {};
    const asset_specific_fields: any = {};
  
    const addFieldIfNotEmpty = (target: any, key: string, value: any) => {
      if (value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        target[key] = value;
      }
    };

    addFieldIfNotEmpty(formData, 'name', presetName);
    addFieldIfNotEmpty(formData, 'folder_name', selectedFolderName);
    addFieldIfNotEmpty(formData, 'fund_group', fundGroup ? [fundGroup] : []);
    addFieldIfNotEmpty(formData, 'funds', fundId ? [fundId] : []);
    addFieldIfNotEmpty(formData, 'start_date_offset', selectedFromDate);
    addFieldIfNotEmpty(formData, 'end_date_offset', selectedToDate);
    addFieldIfNotEmpty(formData, 'user_id', 'sanjay');
    addFieldIfNotEmpty(formData, 'data_type', selectedTypeOfData);
    addFieldIfNotEmpty(formData, 'uuid', uuid);
    addFieldIfNotEmpty(asset_specific_fields, 'asset_key_types', assetIdType);
    addFieldIfNotEmpty(asset_specific_fields, 'asset_keys', assetId ? [assetId] : []);

    if (Object.keys(asset_specific_fields).length > 0) {
      formData.asset_specific_fields = asset_specific_fields;
    }

    return formData;
  };
  
  const formData = buildFormData();
  
  try {
    const response = await axios.post(`${services.GET_PRESETS}`, JSON.stringify([formData]), {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    Promise.resolve(response.data.data)
      .then((data) => {
        if (data === null) {
          throw new Error(response.data.message);
        }
        console.log("API Response:", response.data);

        if (!folderId) {
          const fileData = {
            uuid,
            ...formData,
          };

          const unassignedFilesString = localStorage.getItem('unassignedFiles');
          const unassignedFiles = unassignedFilesString ? JSON.parse(unassignedFilesString) : [];
          unassignedFiles.push(fileData);
          localStorage.setItem('unassignedFiles', JSON.stringify(unassignedFiles));
          localStorage.setItem('presetName', JSON.stringify(data));
        }
        // window.location.reload();
      });
  } catch (error: any) {
    throw new Error(error?.response?.status || error.message)
  }
};


const formatDate = (date: Date | null) => {
  if (date && isValid(date)) {
    if (isToday(date)) return "DATE_SPEC_TODAY";
    if (isYesterday(date)) return "DATE_SPEC_YESTERDAY";
    if (isTomorrow(date)) return "DATE_SPEC_TOMORROW";
    return format(date, "yyyy-MM-dd");
  }
  return "";
};

const processDates = (data: any) => {
  return data.map((item: any) => {
    const processedDocuments = item.documents.map((doc: any) => {
      let processedStartDate: Date;
      let processedEndDate: Date;

      // Check and process start_date
      if (doc.start_date === DATE_SPEC_TODAY || doc.start_date === DATE_SPEC_YESTERDAY || doc.start_date === DATE_SPEC_TOMORROW) {
        processedStartDate = getSpecialDate(doc.start_date);
      } else {
        processedStartDate = new Date(doc.start_date);
      }

      // Check and process end_date
      if (doc.end_date === DATE_SPEC_TODAY || doc.end_date === DATE_SPEC_YESTERDAY || doc.end_date === DATE_SPEC_TOMORROW) {
        processedEndDate = getSpecialDate(doc.end_date);
      } else {
        processedEndDate = new Date(doc.end_date);
      }

      return {
        ...doc,
        start_date: processedStartDate,
        end_date: processedEndDate,
      };
    });
    return {
      ...item,
      documents: processedDocuments,
    };
  });
};

const createFolder = async (folderTitle: string) => {
  try {
    const uuid = uuid_v4();
    const newDocument: Document = {
      uuid: uuid,
      name: "",
      data_type: "",
      end_date_offset: 0,
      fund_group: [],
      funds: [],
      start_date_offset:0,
      folder_name: folderTitle,
      user_id: "",
      asset_specific_fields: {
        asset_key_types: "",
        asset_keys: [],
      }
    };
    await axios.post(`${services.GET_PRESETS}`, [newDocument]);
  } catch (error) {
    console.error("Error creating folder:", error);
  }
}

const renameDocument = async (docId: string, newName: string) => {
  if (!newName || newName.trim() === "") {
    throw new Error("Document name cannot be null or empty.");
  }

  try {
    await axios.post(`${services.GET_PRESETS}`, [{ uuid: docId, name: newName }]);
    console.log("Document renamed:", docId, newName);
    return true;
  } catch (error) {
    console.error("Error renaming document:", error);
    return false;
  }
};


const renameDocumentFolder = async (docId: string, newName: string, renameUuids: string) => {
  if (!newName || newName.trim() === "") {
    throw new Error("Document name cannot be null or empty.");
  }
  try {
    await axios.post(`${services.GET_PRESETS}`, [{ uuid: renameUuids, folder_name: newName }]);
    console.log("Document renamed:", docId, newName);
    return true;
  } catch (error) {
    console.error("Error renaming document:", error);
    return false;
  }
};

const clearFolderFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  console.log(`Cleared ${key} from localStorage`);
  // window.location.reload();
};

export {
  mapData,
  mapDatas,
  savePreset,
  formatDate,
  processDates,
  createFolder,
  renameDocument,
  renameDocumentFolder,
  clearFolderFromLocalStorage,
}
