import React from 'react';
import { TextInput } from '@mantine/core';
import { AssetTextInputProps } from '../../types';
import AutoSuggestion from '../AutoSuggestion';
import { InputError } from '../Common/Errors/InputError';

export const AssetTextInput: React.FC<AssetTextInputProps> = ({
  label,
  value = '',
  onChange,
  onBlur,
  onFocus,
  className,
  min,
  placeholder,
  autoComplete,
  onChangeAutoComplete,
  errorMessage,
}) => {
  return (
    autoComplete ? (
      <div>
        <AutoSuggestion
          label={label}
          value={value}
          onChange={onChangeAutoComplete}
          onFocus={onFocus}
          errorMessage={errorMessage}
          className={className}
          />
      </div>

) : (
  <div>
        <TextInput
          label={label}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={className}
          min={min}
          placeholder={placeholder}
          autoComplete="off"
          />
          {errorMessage && <InputError message={errorMessage}/>}      
      </div>
    )
  );
};

