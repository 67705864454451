import axios from 'axios';
import { toast } from 'react-toastify';
import { services } from '../../services';
import { PayloadType } from '../../types';
import { stringToDate } from '../../utils/dateUtils';

export const handleSubmit = async (
assetName: string, currency: string, issueCountry: string, tradingCountry: string, riskCountry: string, sector: string, assetClass: string, instrument: string, primaryExchange: string, parentAssetId: string, underlyingAssetId: string, ratioToUnderlying: number, equivalentVotingRights: number, priceMultiplier: number, noticeDate: string, lastTradeDate: string, expiryDate: string, location: any, navigate: any, tradableAsset: boolean,
) => {
    const normalizedInstrument = instrument?.trim();
    const normalizedAssetClass = assetClass?.trim();
    const assetKey = location.state?.data?.asset_key;

    let payloadEditKey: string | undefined;
    let payloadEditValue: string | undefined;

    if (assetKey) {
        const assetKeyEntries = Object.entries(assetKey);
        if (assetKeyEntries.length > 0) {
            [payloadEditKey, payloadEditValue] = assetKeyEntries[0] as [string, string];
        }
    }

    const commonPayload = {
        asset_name: assetName,
        currency,
        issue_country: issueCountry,
        trading_country: tradingCountry,
        risk_country: riskCountry,
        ...(assetKey && { asset_key: assetKey }),
    };

    if (normalizedAssetClass === 'Equity' && normalizedInstrument === 'Stock') {
        const equityPayload = {
					equity: {
						asset_type: 'STOCK',
						primary_exchange: primaryExchange,
						parent_asset_id: parentAssetId,
						underlying_asset_id: underlyingAssetId,
						ratio_to_underlying_asset: ratioToUnderlying,
						equivalent_voting_rights: equivalentVotingRights,
						price_multiplier: priceMultiplier,
					},
					asset_group: "EQUITY",
					sector: sector,
					not_tradable: tradableAsset,
					...commonPayload,
				};
        handleApiCall(equityPayload);
    } else if (normalizedAssetClass === 'Currency' && normalizedInstrument === 'Spot') {
        const fxPayload = {
            FX: {
                asset_type: 'SPOT',
                price_multiplier: priceMultiplier,
            },
            asset_group: 'CURRENCY',
            ...commonPayload,
        };
        handleApiCall(fxPayload);
    } else if (normalizedAssetClass === "Equity" && normalizedInstrument === "Futures") {
			const futuresPayload = {
				Futures: {
					asset_type: "EQUITY_INDEX_FUTURES",
					price_multiplier: priceMultiplier,
					notice_date: stringToDate(noticeDate),
					last_trade_date: stringToDate(lastTradeDate),
					expiry_date: stringToDate(expiryDate),
					underlying_asset_id: underlyingAssetId,
				},
            asset_group: 'FUTURES',
				...commonPayload,
			};
			handleApiCall(futuresPayload);
    } else if (normalizedAssetClass === 'Equity' && instrument === 'ETF') {
			const etfPayload = {
				equity: {
					asset_type: normalizedInstrument,
					price_multiplier: priceMultiplier,
				},
            asset_group: 'EQUITY',
				...commonPayload,
			};
			handleApiCall(etfPayload);
		} else {
        console.error('Invalid asset class or instrument. No API call will be made.');
		}

    async function handleApiCall(payload: PayloadType) {
        try {
            const apiUrl = payloadEditKey && payloadEditValue
                ? `${services.ASSET_INVESTMENT}?asset_id=${payloadEditValue}&asset_id_type=${payloadEditKey}&created_by=CREATED_BY_USER`
                : `${services.ASSET_INVESTMENT}?created_by=CREATED_BY_USER`;

            const response = await axios.post(apiUrl, [payload]);
            navigate(-1)
        } catch (error: any) {
            console.error('API Error:', error);
            const errorMessage =
                 error.response?.data?.detail || error.response?.data || 'API_ERROR_MESSAGE';
            toast.error(errorMessage); 
        }
    }
};
