import { FC, useEffect, useMemo, useState } from "react";
import {
	AgGridEvent,
	CellClickedEvent,
	CellDoubleClickedEvent,
	ColDef,
	GridOptions,
} from "ag-grid-community";
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./Agstyles.css";
import { Box, Button, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import axios from "axios";
import { AgTableComponentProps, RowInfo } from "../../types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { services } from "../../services";
import { ConstantUtils } from "../../utils/constantUtils";
import { hasWritePermission } from "../../utils/permissionUtils";
import { applyDecorators, getTableData, getToleranceSymbol } from "../../utils/conversions";
import CustomNoRowsOverlay from "../Common/customNoRowsOverlay";
import { getBadgeStyles } from "../../utils/getStyles";
import { useColDefs } from "./UseColDef";
import { customHeight, gridOptions, rowHeightLarge, rowSelectionTypeSingle } from "../CustomTable/utilsAgTable";
import CustomTable from "../CustomTable/CustomTable";
import { LoaderIcon } from "../Common/LoaderIcon";
import { getCurrentUTCDate } from "../../utils/dateUtils";

export const AgTableComponent: FC<AgTableComponentProps> = ({
	rowData,
	queryParams,
	transactionId,
	isFundLocked,
	fetchDataForTable,
}) => {
	const { t, i18n } = useTranslation();
	const theme = useMantineTheme();
	const styles = getBadgeStyles(theme);
	const [languageKey, setLanguageKey] = useState(i18n.language);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const location = useLocation(); // Use useLocation hook
	const { pathname, search } = location;
	const currentLocation = location.pathname;
	const urlQueryParams = new URLSearchParams(search);
	const selected = decodeURI(urlQueryParams.get("selected") || "");
	const tab = decodeURI(urlQueryParams.get("tab") || "");
	let target_mode = pathname.split("/")[1];
	target_mode = target_mode.toUpperCase().replace(/-/g, "_");
	const tableData = getTableData("fund");
	const [checkState, setCheckState] = useState<string | boolean>();
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
	const [loadingButtons, setLoadingButtons] = useState<Record<number, boolean>>({});
	const [triggerFundsApi, setTriggerFundsApi] = useState(false);
	const [wipButtonStates, setWipButtonStates] = useState<Record<number, string>>({});
	const inBadge = styles.inBadge;
	const outBadge = styles.outBadge;
	const commonCellStyle = styles.commonCellStyle;
	const cellWithoutBold = styles.cellWithoutBold;
	const commonCellStyleWithoutTolerance = styles.commonCellStyleWithoutTolerance;
	const scrollBorder = styles.scrollBorder;
	const cellicon = styles.cellicon;
	const fundIdHeader = t("FUND_ID");
	const CANCEL = t("CANCEL");
	const I_M_STARTING = t("I_M_STARTING");
	const ME = t("ME");
	const calculateMaxHeight = target_mode === "ALL_FUND_STATUS" ? true : false;
	const { target_date, calc_date } = queryParams || {};
	const gridOptions: GridOptions = useMemo(
		() => ({
			immutableData: true,
			getRowId: (params) => {
				return params.data.fund_id;
			},
			suppressPropertyNamesCheck: true,
		}),
		[],
	);

	useEffect(() => {
		setLanguageKey(i18n.language);
	}, [i18n.language]);

	useEffect(() => {
		const buttonState = isFundLocked ? ME : I_M_STARTING;
		setWipButtonStates((prevState) => ({
			...prevState,
			[transactionId || ""]: buttonState,
		}));
	}, [transactionId, isFundLocked]);

	useEffect(() => {
		setWipButtonStates({});
		setLoadingButtons({});
		setIsButtonDisabled(false);
		setCheckState(false);
		if (fetchDataForTable) {
			fetchDataForTable();
		}
	}, [triggerFundsApi]);

	const targetModeForAllFund = target_mode === "ALL_FUND_STATUS" ? 'MARKET_ON_CLOSE' : target_mode
	const targetDateForAllFund = target_date || getCurrentUTCDate()
	const calcDateForAllFund = calc_date || getCurrentUTCDate()
	const defaultSelected = 'READY'

	const handleWipButtonClick = async (rowID: any, params: any) => {
		const { fund_id, fund_group, is_fund_locked, transaction_id } = params.data;
		const currentState = wipButtonStates[Number(rowID)];
		const newState = currentState === I_M_STARTING ? ME : I_M_STARTING;

		setWipButtonStates((prevStates) => ({
			...prevStates,
			[rowID]: newState,
		}));

		setLoadingButtons((prev) => ({
			...prev,
			[rowID]: true,
		}));

		if (!hasWritePermission(fund_group, "write")) {
			toast.error(t("NOT_AUTHORIZE_MESSAGE"));
			setLoadingButtons((prev) => ({
				...prev,
				[rowID]: false,
			}));
			return;
		}

		const event_type =
			is_fund_locked === "true"
				? ConstantUtils.orderClaimStates.unclaim
				: ConstantUtils.orderClaimStates.claim;

		const url = `${services.ORDERCLAIM}?fund_id=${fund_id}`;
		const payload = {
			fund_id,
			target_date: targetDateForAllFund,
			target_mode: targetModeForAllFund,
			transaction_id,
			event_type,
			calc_date: calcDateForAllFund,
		};

		const handlePending = (retryCount: number, event_type: string, uuid: string) => {
			const statusUrl = `${services.ORDERCLAIMSTATUS}?fund_id=${fund_id}&target_date=${targetDateForAllFund}&calc_date=${calcDateForAllFund}&transaction_id=${transaction_id}&target_mode=${targetModeForAllFund}&event_type=${event_type}&uuid=${uuid}`;

			axios
				.get(statusUrl)
				.then(async (orderClaimResponse) => {
					const orderClaimData = orderClaimResponse.data.data.response_type;

					if (ConstantUtils.successTypes.includes(orderClaimData)) {
						const successState =
							ConstantUtils.successTypes[rowID] === ConstantUtils.orderClaimEnum.orderClaimSuccess
								? ME
								: I_M_STARTING;

						setWipButtonStates((prevStates) => ({
							...prevStates,
							[transaction_id]: successState,
						}));

						await new Promise((resolve) => setTimeout(resolve, 1000));

						setTriggerFundsApi((prev) => !prev);
						setLoadingButtons((prev) => ({
							...prev,
							[transaction_id]: false,
						}));
					} else if (orderClaimData === ConstantUtils.orderClaimStatus.orderClaimStatusPending || 
						orderClaimData === ConstantUtils.orderUnclaimStatus.orderUnclaimStatusPending) {
						setWipButtonStates((prevStates) => ({
							...prevStates,
							[transaction_id]: t("LOADING"),
						}));

						if (retryCount < 30) {
							setTimeout(() => handlePending(retryCount + 1, event_type, uuid), 1000);
						} else {
							handleRetryExceeded();
						}
					}
				})
				.catch((error) => {
					console.error(error);
					handleFailure();
				});
		};

		const handleRetryExceeded = () => {
			setWipButtonStates((prevStates) => ({
				...prevStates,
				[transaction_id]: ME,
			}));
			setLoadingButtons((prev) => ({
				...prev,
				[transaction_id]: false,
			}));
			toast.error(t("REFRESH_NEEDED"));
		};

		const handleFailure = () => {
			toast.error(t("ORDER_UPDATE_MESSAGE"));
			setWipButtonStates((prevStates) => ({
				...prevStates,
				[rowID]: I_M_STARTING,
			}));
			setLoadingButtons((prev) => ({
				...prev,
				[transaction_id]: false,
			}));
		};

		axios
			.post(url, payload)
			.then((response) => {
				const { data: datacheck, uuid_str: uuid } = response.data;
				const uuid_from_order_claim_unclaim = response.data.uuid;
				localStorage.setItem("uuid", uuid_from_order_claim_unclaim);


				if (response.data.response_type === ConstantUtils.orderClaimEnum.orderClaimPending || 
					response.data.response_type === ConstantUtils.orderUnclaimEnum.orderUnclaimPending) {
					handlePending(0, event_type, uuid_from_order_claim_unclaim);
				} else {
					toast.error(t("ORDER_CLAIM_FAILED"));
					setLoadingButtons((prev) => ({
						...prev,
						[rowID]: false,
					}));
				}
			})
			.catch((error) => {
				console.error(error);
				toast.error(t("ORDER_CLAIM_FAILED"));
			});
	};

	const handleFundIdCellClick = (params: CellClickedEvent<RowInfo, any>) => {
	};

	const handleFundIdCellDoubleClick = async (params: CellDoubleClickedEvent<RowInfo, any>) => {
		if (params.data) {
			const fundId = params.data.fund_id;
			const transaction_id = params.data.transaction_id;
			const is_fund_locked = params.data.is_fund_locked;
			const fund_summary_type = params.data.fund_summary_type;
			const fund_group = params.data.fund_group;

			let initialState = I_M_STARTING;
			if (is_fund_locked === "true") {
				initialState = ME;
			} else if (is_fund_locked === "false") {
				initialState = I_M_STARTING;
			}

			if (target_mode === "MARKET_ON_CLOSE_PLUS_DAYS") {
				target_mode = "MARKET_ON_CLOSE";
			}

			const rowId = params.data.sr_no;
			const currentState = wipButtonStates[rowId] || initialState;
			const newState = initialState === I_M_STARTING ? ME : I_M_STARTING;

			try {
				const { target_date, calc_date } = queryParams || {};
				const checktargetmode = target_mode === "ALL_FUND_STATUS" ? fund_summary_type : target_mode || tab;
				const navigateUrl = `${routes.FUNDSDATA}/${fundId}/${transaction_id}/${checktargetmode}/${selected || defaultSelected}/${targetDateForAllFund}/${fund_group}/${calcDateForAllFund}`;

				const disabledStatesForNavigation = {
					[ME]: "false",
					[I_M_STARTING]: "true",
				};
				let url = `${navigateUrl}?disabled=${disabledStatesForNavigation[currentState]}`;
				navigate(url);

				setWipButtonStates((prevStates) => ({
					...prevStates,
					[rowId]: newState,
				}));
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleWipButtonHover = (rowId: number) => {
		const test = checkState === true ? I_M_STARTING : ME;
		setWipButtonStates((prevStates) => {
			const currentState = prevStates[rowId] || test;
			if (currentState === ME) {
				return {
					...prevStates,
					[rowId]: CANCEL,
				};
			}
			return prevStates;
		});
	};
	const rowHeight = 40;
	const maxVisibleRows = 12;
	const gridHeight = useMemo(() => {
		const rowsToShow = Math.min(rowData.length, maxVisibleRows);
		return rowsToShow * rowHeight + 70;
	}, [rowData]);
	const handleWipButtonLeave = (rowId: number) => {
		const test = checkState === true ? I_M_STARTING : ME;
		setWipButtonStates((prevStates) => {
			const currentState = prevStates[rowId] || I_M_STARTING;
			if (currentState === CANCEL) {
				return {
					...prevStates,
					[rowId]: ME,
				};
			}
			return prevStates;
		});
	};

	const renderCellWithViolations = (
		params: any,
		valueKey: string,
		toleranceKey: string,
		toleranceLowerKey: string,
		toleranceUpperKey: string,
		decoratorsKey: string,
	) => {
		const value = parseFloat(params.data[valueKey]);
		const tolerance = params.data[toleranceKey];
		const toleranceLower = params.data[toleranceLowerKey];
		const toleranceUpper = params.data[toleranceUpperKey];
		const formattedValue = applyDecorators(
			value,
			tableData.find((row) => row.key === decoratorsKey)?.decorators,
		);

		if (tolerance && tolerance !== "0.00%") {
			const symbol = getToleranceSymbol(tolerance, toleranceUpper, toleranceLower);
			const backgroundColor =
				value >= toleranceLower && value <= toleranceUpper
					? theme.colors.yellow[0]
					: theme.colors.red[0];

			return (
				<div style={{ backgroundColor, textAlign: "right" }}>
					<div
						className='spendcashstyles'
						style={{ marginBottom: "-4rem", paddingRight: "0.813rem" }}
					>
						{formattedValue}
					</div>
					<br />
					<div
						style={{ color: theme.colors.grey[5], paddingRight: "0.813rem" }}
						className='secondtextincellfunds'
					>
						{`${symbol}${tolerance}`}
					</div>
				</div>
			);
		}

		return <div className='firsttextincell'>{formattedValue}</div>;
	};

	const colDefs = useColDefs({
		selected,
		fundIdHeader,
		t,
		handleFundIdCellClick,
		hasWritePermission,
		renderCellWithViolations,
		inBadge,
		outBadge,
		cellWithoutBold,
		cellicon,
		commonCellStyle,
		commonCellStyleWithoutTolerance,
		wipButtonStates,
		handleWipButtonClick,
		handleWipButtonHover,
		handleWipButtonLeave,
		isButtonDisabled,
		loadingButtons,
		theme,
		setCheckState,
		ME,
		I_M_STARTING,
	});

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			menuTabs: ["filterMenuTab"],
			filter: "agMultiColumnFilter",
			suppressStickyLabel: true,
			headerClass: "custom-header",
			resizable: true,
			wrapHeaderText: true,
			autoHeaderHeight: true,
			headerPosition: "sticky",
			sticky: true,
			flex: 1,
			initialWidth: 55,
		};
	}, []);

	const tableHeight = customHeight(rowData);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	const componentLocation = __filename.split("/").pop();
	const hideColumns = componentLocation === "AllFundStatus.tsx";

	colDefs.filter((colDef) => {
		if (hideColumns) {
			return colDef.field !== "next_step" && colDef.field !== "wip";
		} else {
			const decorators = tableData.find((row) => row.key === colDef.field)?.decorators;
			colDef.valueFormatter = ({ value }) => applyDecorators(value, decorators);
			return true;
		}
	});

	const filteredColDefs = colDefs.filter((colDef) => {
		if (
			selected === "Completed" ||
			currentLocation.includes(`${routes.ORDERCOMPLETION}`) ||
			selected === "All-Fund-Status"
		) {
			return colDef.field !== "next_step" && colDef.field !== "wip";
		}
		return true;
	});

	return (
		<div>
			{isLoading ? (
				<Box h={"80vh"}>
					<LoaderIcon name='loading-large' alt='loading icon' />
				</Box>
			) : (
				<>
					<CustomTable rowData={rowData} columnDefs={filteredColDefs} fullLength={calculateMaxHeight ? true : false} rowHeight={rowHeightLarge} defaultColDef={defaultColDef} rowSelection={rowSelectionTypeSingle} onSortChanged={(e: AgGridEvent) => {
						e.api.refreshCells();
					}} onCellClicked={handleFundIdCellClick}
						onCellDoubleClicked={handleFundIdCellDoubleClick} className="order-tables"
						largeHeader
					/>
					<ToastContainer position='top-right' autoClose={8000} />
				</>
			)}
		</div>
	);
};

export default AgTableComponent;
