import React from 'react';
import { Badge, MantineTheme, Text } from '@mantine/core';
import { t } from 'i18next';
export function getBadgeStyles(theme: MantineTheme) {
    return {
        inBadge: (
            <Badge
                color={theme.colors.green[0]}
                size="xs"
                variant="filled"
                pt={'0.02rem'}

                w={'2rem'}
            >
                <Text size='0.6875rem' fw='700' ta={'center'} c={theme.colors.dark[9]}>
                    {t("IN")}
                </Text>
            </Badge>
        ),
        outBadge: (
            <Badge
                size="xs"
                variant="filled"
                color={theme.colors.red[0]}
                pt={'0.02rem'}
                w={'2.815rem'}
            >
                <Text size='0.6875rem' fw='700' ta={'center'} c={theme.colors.dark[9]}>
                    {t("OUT")}
                </Text>
            </Badge>
        ),
        commonCellStyle: {
            fontWeight: "700",
            fontSize: theme.fontSizes.md,
            textAlign: "right",
            padding: 0,
        },
        cellWithoutBold: {
            fontSize: theme.fontSizes.md,
            textAlign: "left",
            paddingLeft: "-15px"
        },
        cellicon: {
            fontWeight: "700",
            fontSize: theme.fontSizes.md,
            display: "flex",
            justifyContent: "center",
        },
        commonCellStyleWithoutTolerance: {
            fontWeight: "600",
            fontSize: theme.fontSizes.md,
            textAlign: "right",
            paddingRight: theme.spacing.sm,
        },
        scrollBorder: {
            borderLeft: '0.5px solid lightgrey',
        },
    };
}
