export class ConstantUtils {

    public static readonly successTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_SUCCESS"
    ];
    
    public static readonly refreshTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_REFRESH",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_REFRESH"
    ];
    
    public static readonly failTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_FAIL",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_FAIL"
    ];

    public static readonly orderClaimStates = {
        claim: "ORDER_CLAIM",
        unclaim: "ORDER_UNCLAIM"
    };

    public static readonly orderApprovalStatus = {
        orderApprovalSuccess: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_APPROVAL_SUCCESS",
        orderApprovalFail: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_APPROVAL_FAIL",
        orderApprovalRefresh: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_APPROVAL_REFRESH",
        orderApprovalPending: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_APPROVAL_PENDING"
    };

    public static readonly orderClaimStatus = {
        orderClaimStatusSuccess: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS",
        orderClaimStatusPending: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_PENDING",

    };

    public static readonly orderClaimEnum = {
        orderClaimSuccess: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS",
        orderClaimPending: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_PENDING"
    };

    public static readonly orderUnclaimEnum = {
        orderUnclaimSuccess: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_SUCCESS",
        orderUnclaimPending: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_PENDING"
    };

    public static readonly orderUnclaimStatus = {
        orderUnclaimStatusPending: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_PENDING",
        orderUnclaimStatusSuccess: "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_SUCCESS"
    };

}

