import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@mantine/core';
import '../Investment.css';
import CustomButton from '../../../../components/Common/Buttons/CustomButton';
import PageTitle from '../../../../components/Common/PageTitle';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateToString } from '../../../../utils/dateUtils';
import { formatInstrument, getInstrumentOptionsBasedOnAssetClass } from '../../../../components/AssetsInvestments/AssetUtils';
import { handleSubmit } from '../../../../components/AssetsInvestments/handleSubmitHelper';
import AssetsSelectFields from '../../../../components/AssetsInvestments/AssetsSelectFields';
import { AssetTextInput } from '../../../../components/AssetsInvestments/AssetTextInput';
import { validateFields } from './assetValidationUtils';
import { ToastContainer } from 'react-toastify';

const AssetForm: React.FC = () => {
    const [assetClass, setAssetClass] = useState<string | null>(null);
    const [instrument, setInstrument] = useState<string | null>(null);
    const [instrumentOptions, setInstrumentOptions] = useState<string[]>([]);
    const [autofillSuggestions, setAutofillSuggestions] = useState<string[]>([]);
    const [priceMultiplier, setPriceMultiplier] = useState<number>(1);
    const [assetName, setAssetName] = useState<string>('');
    const [currency, setCurrency] = useState<string>('');
    const [issueCountry, setIssueCountry] = useState<string>('');
    const [tradingCountry, setTradingCountry] = useState<string>('');
    const [riskCountry, setRiskCountry] = useState<string>('');
    const [sector, setSector] = useState<string>('');
    const [tradableAsset, setTradableAsset] = useState<boolean>(false);
    const [primaryExchange, setPrimaryExchange] = useState<string>('');
    const [parentAssetId, setParentAssetId] = useState<string>('');
    const [underlyingAssetId, setUnderlyingAssetId] = useState<string>('');
    const [ratioToUnderlying, setRatioToUnderlying] = useState<number | null>(null);
    const [equivalentVotingRights, setEquivalentVotingRights] = useState<number | null>(null);
    const [noticeDate, setNoticeDate] = useState<string>('');
    const [lastTradeDate, setLastTradeDate] = useState<string>('');
    const [expiryDate, setExpiryDate] = useState<string>('');
    const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});

    const location = useLocation();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (location.state && location.state.data && location.state.data.asset_group === 'EQUITY') {
            const { data } = location.state;
            setAssetClass('Equity');
            setInstrument(formatInstrument(data.equity.asset_type));
            setAssetName(data.asset_name);
            setCurrency(data.currency);
            setIssueCountry(data.issue_country);
            setTradingCountry(data.trading_country);
            setRiskCountry(data.risk_country);
            setSector(data.sector);
            setPrimaryExchange(data.equity.primary_exchange);
            setParentAssetId(data.equity.parent_asset_id);
            setUnderlyingAssetId(data.equity.underlying_asset_id);
            setPriceMultiplier(data.equity.price_multiplier);
            setRatioToUnderlying(data.equity.ratio_to_underlying_asset);
            setEquivalentVotingRights(data.equity.equivalent_voting_rights);
            setTradableAsset(!data.not_tradable);
        }
        if (location.state && location.state.data && location.state.data.asset_group === 'FUTURES') {
            const { data } = location.state;
            setAssetClass('Equity');
            setInstrument(formatInstrument(data.Futures.asset_type || ''));
            setAssetName(data.asset_name || '');
            setCurrency(data.currency || '');
            setIssueCountry(data.issue_country || '');
            setTradingCountry(data.trading_country || '');
            setRiskCountry(data.risk_country || '');
            setSector(data.sector || '');
            setPrimaryExchange(data.Futures.primary_exchange || '');
            setParentAssetId(data.Futures.parent_asset_id || '');
            setUnderlyingAssetId(data.Futures.underlying_asset_id || '');
            setPriceMultiplier(data.Futures.price_multiplier || 1);
            setRatioToUnderlying(data.Futures.ratio_to_underlying_asset || null);
            setEquivalentVotingRights(data.Futures.equivalent_voting_rights || null);
            setNoticeDate(dateToString(data.Futures.notice_date) || '');
            setLastTradeDate(dateToString(data.Futures.last_trade_date) || '');
            setExpiryDate(dateToString(data.Futures.expiry_date) || '');
        }
        if (location.state && location.state.data && location.state.data.asset_group === 'CURRENCY') {
            const { data } = location.state;
            setAssetClass('Currency');
            setInstrument(formatInstrument(data.FX.asset_type));
            setAssetName(data.asset_name || '');
            setCurrency(data.currency || '');
            setIssueCountry(data.issue_country || '');
            setTradingCountry(data.trading_country || '');
            setRiskCountry(data.risk_country || '');
            setPriceMultiplier(data.FX.price_multiplier || 1);
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state && location.state.data) {
            const { data } = location.state;

            let formattedInstrument = '';
            if (data.asset_group === 'EQUITY') {
                formattedInstrument = formatInstrument(data.equity.asset_type || '');
            } else if (data.asset_group === 'FUTURES') {
                formattedInstrument = formatInstrument(data.Futures.asset_type || '');
            }

            const options = getInstrumentOptionsBasedOnAssetClass(data.asset_group);
            setInstrumentOptions(options);

            if (options.includes(formattedInstrument)) {
                setInstrument(formattedInstrument);
            } else {
                setInstrument(null);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state && location.state.data && location.state.data.asset_group === 'CURRENCY') {
            const { data } = location.state;
            setInstrument(formatInstrument(data.FX.asset_type));
        }
    }, [location.state]);

    const handleAssetClassChange = (value: string | null) => {
        setAssetClass(value);
        if (value) {
            const options = getInstrumentOptionsBasedOnAssetClass(value);
            setInstrumentOptions(options);
            setInstrument((prev) => options.includes(prev!) ? prev : null);
        } else {
            setInstrumentOptions([]);
            setInstrument(null);
        }
    };

    const handleDateChange = (value: string, field: string) => {
        const dateFormatRegex = /^(0[1-9]|1[0-2])\/([0-2][0-9]|3[01])\/\d{4}$/;
        if (field === 'noticeDate') setNoticeDate(value);
        if (field === 'lastTradeDate') setLastTradeDate(value);
        if (field === 'expiryDate') setExpiryDate(value);
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [field]: value && !dateFormatRegex.test(value) ? 'Please match the format mm/dd/yyyy' : '',
        }));
    };

    const handleAutofillChange = (searchValue: string) => {
        const filteredSuggestions = (autofillSuggestions || []).filter((item) =>
            item.toLowerCase().includes(searchValue.toLowerCase())
        );
        setAutofillSuggestions(filteredSuggestions);
    };

    const handleInputFocus = (key: string) => {
        setInputErrors((prevErrors) => {
            const { [key]: _, ...rest } = prevErrors;
            return rest;
        })
    };

    const handleSubmitClick = () => {
        // common required fields for all Equity-ETF, Equity-Stock, Equity-Futures, Currency-spot
        const commonRequiredFields = [
            { key: "assetClass", value: assetClass },
            { key: "instrument", value: instrument },
            { key: "assetName", value: assetName?.trim() },
            { key: "currency", value: currency?.trim() },
            { key: "issueCountry", value: issueCountry?.trim() },
            { key: "tradingCountry", value: tradingCountry?.trim() },
            { key: "riskCountry", value: riskCountry?.trim() },
            { key: "priceMultiplier", value: priceMultiplier },
        ];
        let requiredFields = commonRequiredFields;
        if (assetClass === "Equity" && instrument === 'Stock') {
            requiredFields = [...commonRequiredFields,
            { key: "primaryExchange", value: primaryExchange?.trim() }
            ]
        } else if (assetClass === 'Equity' && instrument === 'Futures') {
            requiredFields = [...commonRequiredFields,
            { key: "noticeDate", value: noticeDate },
            { key: "lastTradeDate", value: lastTradeDate },
            { key: "expiryDate", value: expiryDate },
            ]
        }
        const { isValid, errors: fieldErrors } = validateFields(requiredFields);
        setInputErrors(fieldErrors);

        if (isValid) {
            handleSubmit(
                assetName,
                currency,
                issueCountry,
                tradingCountry,
                riskCountry,
                sector,
                assetClass!,
                instrument!,
                primaryExchange,
                parentAssetId,
                underlyingAssetId,
                ratioToUnderlying as number,
                equivalentVotingRights as number,
                priceMultiplier,
                noticeDate,
                lastTradeDate,
                expiryDate,
                location,
                navigate,
                tradableAsset
            );
        }
    };


    return (
        <Box>
            <PageTitle
                TITLE={location.state && location.state.data ? 'EDIT_ASSET' : 'CREATE_NEW_ASSET'}
                import_asset={location.state && location.state.data ? '' : 'IMPORT'}
                back
            />
            <Flex justify="center" mt={'1.3rem'} gap="xl">
                <Flex direction="column" gap="md" className="left-section">
                    <AssetsSelectFields
                        label={t('ASSET_CLASS')}
                        data={['Equity', 'Currency']}
                        value={assetClass}
                        onChange={handleAssetClassChange}
                        onFocus={() => handleInputFocus('assetClass')}
                        errorMessage={inputErrors.assetClass}
                    />
                    <AssetsSelectFields
                        label={t('INSTRUMENT')}
                        data={instrumentOptions}
                        value={instrument}
                        onChange={(value) => setInstrument(value)}
                        onFocus={() => handleInputFocus('instrument')}
                        disabled={!instrumentOptions.length}
                        errorMessage={inputErrors.instrument}
                    />
                    <AssetTextInput
                        label={t('ASSET_NAME')}
                        value={assetName}
                        onChange={(e) => setAssetName(e.target.value)}
                        onFocus={() => handleInputFocus('assetName')}
                        className="asset-input"
                        errorMessage={inputErrors.assetName}
                    />

                    <AssetTextInput
                        label={'CURRENCY'}
                        value={currency}
                        onChangeAutoComplete={(value: string) => {
                            setCurrency(value);
                            handleAutofillChange(value);
                        }}
                        onFocus={() => handleInputFocus('currency')}
                        errorMessage={inputErrors.currency}
                        className="asset-input"
                        autoComplete
                    />

                    <AssetTextInput
                        label="ISSUE_COUNTRY"
                        value={issueCountry}
                        onChangeAutoComplete={(value: string) => {
                            setIssueCountry(value);
                            handleAutofillChange(value);
                        }}
                        onFocus={() => handleInputFocus('issueCountry')}
                        errorMessage={inputErrors.issueCountry}
                        className="asset-input"
                        autoComplete
                    />

                    <AssetTextInput
                        label={'TRADING_COUNTRY'}
                        value={tradingCountry}
                        onChangeAutoComplete={(value: string) => {
                            setTradingCountry(value);
                            handleAutofillChange(value);
                        }}
                        onFocus={() => handleInputFocus('tradingCountry')}
                        errorMessage={inputErrors.tradingCountry}
                        className="asset-input"
                        autoComplete
                    />

                    <AssetTextInput
                        label={'RISK_COUNTRY'}
                        value={riskCountry}
                        onChangeAutoComplete={(value: string) => {
                            setRiskCountry(value);
                            handleAutofillChange(value);
                        }}
                        onFocus={() => handleInputFocus('riskCountry')}
                        errorMessage={inputErrors.riskCountry}
                        className="asset-input"
                        autoComplete
                    />

                    <AssetTextInput
                        label={t('PRICE_MULTIPLIER')}
                        value={priceMultiplier}
                        min={1}
                        onChange={(e) => setPriceMultiplier(Number(e.target.value))}
                        onFocus={() => handleInputFocus('priceMultiplier')}
                        errorMessage={inputErrors.priceMultiplier}
                        className="asset-input"
                    />
                </Flex>

                {instrument === 'Stock' && (
                    <Flex direction="column" gap="md" className="right-section right-section-margin">
                        <AssetsSelectFields
                            label={t('TRADABLE_ASSET')}
                            data={['Yes', 'No']}
                            value={tradableAsset ? 'Yes' : 'No'}
                            onChange={(value) => setTradableAsset(value === 'Yes')}
                        />
                        <AssetTextInput
                            label={t('PRIMARY_EXCHANGE')}
                            value={primaryExchange}
                            onChange={(e) => setPrimaryExchange(e.target.value)}
                            onFocus={() => handleInputFocus('primaryExchange')}
                            errorMessage={inputErrors.primaryExchange}
                            className="asset-input"
                        />
                        <AssetTextInput
                            label={`${t('SECTOR') } ${t('OPTIONAL')}`}
                            value={sector}
                            onChange={(e) => setSector(e.target.value)}
                            className="asset-input"
                        />
                        <AssetTextInput
                            label={`${t('PARENT_ASSET_ID') } ${t('OPTIONAL')}`}                            
                            value={parentAssetId}
                            onChange={(e) => setParentAssetId(e.target.value)}
                            className="asset-input"
                            />
                        <AssetTextInput
                            label={`${t('UNDERLYING_ASSET_ID') } ${t('OPTIONAL')}`}                        
                            value={underlyingAssetId}
                            onChange={(e) => setUnderlyingAssetId(e.target.value)}
                            className="asset-input"
                        />
                        <AssetTextInput
                            label={`${t('RATIO_TO_UNDERLYING_ASSET')} ${t('OPTIONAL')}`}
                            value={ratioToUnderlying}
                            min={0}
                            onChange={(e) => setRatioToUnderlying(Number(e.target.value) || null)}
                            className="asset-input"
                            />
                        <AssetTextInput
                            label={`${t('EQUIVALENT_VOTING_RIGHTS')} ${t('OPTIONAL')}`}
                            value={equivalentVotingRights}
                            min={0}
                            onChange={(e) => setEquivalentVotingRights(Number(e.target.value || null))}
                            className="asset-input"
                        />
                    </Flex>
                )}
                {instrument === 'Futures' && (
                    <Flex direction="column" gap="md" className="right-section right-section-margin">
                        <AssetTextInput
                            label={t('NOTICE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={noticeDate}
                            onChange={(e) => handleDateChange(e.target.value, 'noticeDate')}
                            onFocus={() => handleInputFocus('noticeDate')}
                            className="investment-search"
                            errorMessage={inputErrors.noticeDate}
                        />

                        <AssetTextInput
                            label={t('LAST_TRADE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={lastTradeDate}
                            onChange={(e) => handleDateChange(e.target.value, 'lastTradeDate')}
                            onFocus={() => handleInputFocus('lastTradeDate')}
                            className="investment-search"
                            errorMessage={inputErrors.lastTradeDate}
                        />

                        <AssetTextInput
                            label={t('EXPIRY_TRADE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={expiryDate}
                            onChange={(e) => handleDateChange(e.target.value, 'expiryDate')}
                            onFocus={() => handleInputFocus('expiryDate')}
                            className="investment-search"
                            errorMessage={inputErrors.expiryDate}
                        />

                        <AssetTextInput
                            label={`${t('UNDERLYING_ASSET_ID')} ${t('OPTIONAL')}`}            
                            value={underlyingAssetId}
                            onChange={(e) => setUnderlyingAssetId(e.target.value)}
                            className="investment-search"
                        />
                    </Flex>

                )}
            </Flex>
            <Flex
                gap="sm"
                justify="center"
                className={instrument === 'Stock' ? 'button-shift-right-section' : 'button-shift-left-section'}
            >
                <CustomButton variant="cancel" children="CANCEL" onClick={handleCancel} />
                <CustomButton
                    variant="create-asset"
                    onClick={handleSubmitClick}
                    children={location.state && location.state.data ? 'UPDATE' : 'CREATE'}
                />
            </Flex>
            <ToastContainer />
        </Box>
    );
};

export default AssetForm;