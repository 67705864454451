import React from "react";
import { Box, Center, Modal, Text } from "@mantine/core";
import CustomButton from "../Buttons/CustomButton";
import { t } from "i18next";
import "./modals.css"

type WarningModalProps = {
    opened: boolean,
    title: string ,
    message: string,
    centered?: boolean,
    withCloseButton?: boolean,
    radius?: string,
    className?: string,
    buttonText?:string,
    onSubmit:()=>void,
}

export const WarningModal: React.FC<WarningModalProps> = ({
    opened = true,
    title,
    message,
    buttonText="OK",
    centered = true,
    withCloseButton = false,
    radius = '1.875rem',
    onSubmit
}) => {

    return (
        <>
            <Modal
                opened={opened}
                onClose={onSubmit}
                centered={centered}
                radius={radius}
                withCloseButton={withCloseButton}
                className="warning-modal" 
                size={'30rem'}
            >
                <Box m={"2rem 5rem 2.5rem"}>
                    <Text ta={'center'} size='1rem' fw={'bold'}>{t(title)}</Text>
                    <Text ta={'center'} mt={'1.5rem'} lh={'1rem'}>{t(message)}</Text>
                    <Center mt={'2.5rem'}>
                        <CustomButton variant='submit' onClick={onSubmit} children={t(buttonText)} />
                    </Center>
                </Box>
            </Modal>
        </>
    )

}