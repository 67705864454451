type DeleteModalState = {
    title: string | null;
    deleteMessage: string | null;
    isOpen: boolean;
    folderId: string | null
    folderName: string | null
    presetId: string | null
};

type DeleteModalAction = {
    type: 'folderReference' | 'presetReference' | "resetDeleteModal";
    payload?: any;
};
export const initialDeleteState: DeleteModalState = {
    title:null,
    deleteMessage: null,
    isOpen: false,
    folderId: null,
    folderName: null,
    presetId: null
};
export const deleteModalReducer = (state: DeleteModalState, action: DeleteModalAction): DeleteModalState => {
    switch (action.type) {
        case "folderReference": {
            return {
                ...state,
                isOpen: action.payload.isOpen,
                title:action.payload.title,
                deleteMessage:action.payload.message,
                folderId: action.payload.folderId,
                folderName: action.payload.folderName,
            }
        }
        case "presetReference": {
            return {
                ...state,
                isOpen: action.payload.isOpen,
                title:action.payload.title,
                deleteMessage:action.payload.message,
                presetId: action.payload.presetId
            }
        }
        case "resetDeleteModal": {
            return initialDeleteState
        }
        default: throw Error('Unknown action: ' + action.type);
    }
}