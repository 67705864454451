import React from 'react';
import { Select } from '@mantine/core';
import { rightSection } from './AssetUtils';
import { InputError } from '../Common/Errors/InputError';


interface AssetsSelectFieldsProps {
  label: string;
  data: string[];
  value: string | undefined | null;
  onChange: (value: string | null) => void;
  onFocus?: (event:React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  searchable?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onSearchChange?: (query: string) => void;
}

const AssetsSelectFields: React.FC<AssetsSelectFieldsProps> = ({
  label,
  data,
  value,
  onChange,
  onFocus,
  searchable = false,
  disabled = false,
  errorMessage,
}) => {
  return (<div>
    <Select
      label={label}
      data={data}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      searchable={searchable}
      disabled={disabled}
      className="asset-input"
      rightSection={rightSection}
      comboboxProps={{ offset: 0 }}
      checkIconPosition="right"
      rightSectionPointerEvents="none"
    />
    {errorMessage && <InputError message={errorMessage}/>}
  </div>
   
  );
};

export default AssetsSelectFields;