import React from "react";
import { Button, useMantineTheme } from "@mantine/core";
import "./CustomButton.css";
import { CustomButtonProps } from "../../../types";
import { t } from "i18next";
import { redirect } from "react-router-dom";

const CustomButton: React.FC<CustomButtonProps> = ({
	onClick,
	children,
	variant,
	disabled = false,
}) => {
	const theme = useMantineTheme();

	const baseStyle = {
		width: "6.8125rem",
		height: "1.875rem",
		fontWeight: 600,
		fontSize: "0.8125rem",
		border: "1px solid lightgrey",
		boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
	};

	const disabledStyle = {
		opacity: 0.4,
	};

	const variantStyles: Record<CustomButtonProps["variant"], React.CSSProperties> = {
		cancel: {
			height: "1.375rem",
			backgroundColor: "transparent",
			color: theme.colors.dark[9],
			textDecoration: "underline",
			textUnderlineOffset: "3px",
			fontSize: "0.8125rem",
			marginRight: "3rem",
			padding: 0,
		},
		create: {
			width: "11.25rem",
			height: "1.5rem",
			backgroundColor: theme.colors.violet[8],
			color: "white",
			fontWeight: 600,
			fontSize: "0.8125rem",
		},
		submit: {
			width: "6.313rem",
			height: "1.375rem",
			backgroundColor: theme.colors.yellow[0],
			color: theme.colors.dark[9],
			border: `1px solid ${theme.colors.grey[0]}`,
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
			fontSize: "0.8125rem",
		},
		"no-issue": {
			...baseStyle,
			backgroundColor: "white",
			color: theme.colors.grey[8],
		},
		"no-issue-disable": {
			...baseStyle,
			backgroundColor: "white",
			color: theme.colors.grey[8],
			...disabledStyle,
		},
		"fixed-issue": {
			...baseStyle,
			backgroundColor: theme.colors.violet[8],
			color: "white",
			marginTop: theme.spacing.sm,
		},
		"fixed-issue-disable": {
			...baseStyle,
			backgroundColor: theme.colors.violet[8],
			color: "white",
			marginTop: theme.spacing.sm,
			...disabledStyle,
		},
		update: {
			width: "6.65rem",
			height: "2.0rem",
			backgroundColor: theme.colors.violet[8],
		},
		discard: {
			width: "9.25rem",
			height: "2.0rem",
			backgroundColor: theme.colors.yellow[0],
			color: theme.colors.grey[8],
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
		},
		"discard-disable": {
			backgroundColor: theme.colors.yellow[0],
			color: theme.colors.grey[8],
			...disabledStyle,
		},
		"create-workitems": {
			backgroundColor: theme.colors.violet[8],
		},
		"create-asset": {
			backgroundColor: theme.colors.violet[8],
		},
		"create-new-asset": {
			backgroundColor: theme.colors.violet[8],
		},
		"create-workitems-disable": {
			backgroundColor: theme.colors.violet[8],
			...disabledStyle,
		},
		"discard-recon": {
			backgroundColor: theme.colors.yellow[0],
		},
		refresh: {
			backgroundColor: theme.colors.yellow[0],
		},
		"contact-support": {
			backgroundColor: theme.colors.yellow[0],
		},
	};

	return (
		<Button
			onClick={onClick}
			style={variantStyles[variant] || {}}
			className={`custom-button ${variant}`}
			disabled={disabled}
		>
			{t(children)}
		</Button>
	);
};

export default CustomButton;
