import { t } from 'i18next';

export const validateFields = (fields: { key: string; value: any }[]) => {
    const fieldErrors: { [key: string]: string } = {};

    fields.forEach(({ key, value }) => {
        if (!value) fieldErrors[key] = t("FIELD_REQUIRED_MESSAGE");
    });

    return {
        isValid: Object.keys(fieldErrors).length === 0,
        errors: fieldErrors,
    };
};
