import React from "react";
import { Flex, Checkbox } from "@mantine/core";
import { t } from "i18next";

interface CheckboxGroupProps<KeyType extends string> {
	checkboxConfig: { key: KeyType; label: string }[];
	checkboxState: Record<KeyType, boolean>;
	onCheckboxChange: (key: KeyType) => void;
}

const CheckboxGroup = <KeyType extends string>({
	checkboxConfig,
	checkboxState,
	onCheckboxChange,
}: CheckboxGroupProps<KeyType>) => {
	return (
		<Flex
			mt='xs'
			direction='row'
			wrap='wrap'
			gap='sm'
			justify='start'
			align='center'
			className='checkbox-smart-query'
		>
			{checkboxConfig.map(({ key, label }) => (
				<Checkbox
					key={key}
					label={t(label)}
					checked={checkboxState[key]}
					onChange={() => onCheckboxChange(key)}
					classNames={{ input: "custom-checkbox-smart-query" }}
				/>
			))}
		</Flex>
	);
};

export default CheckboxGroup;
