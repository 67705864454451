import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import DemoStarter from "../pages/DemoStarter/DemoStarter";
import { PageNotFound } from "../pages/404Page.tsx/PageNotFound";
import FundData from "../pages/AlgoJournal/FundData";
import AllFundStatus from "../pages/AllFundStatus/AllFundStatus";
import CoacManagement from "../pages/CoacManagement";
import AccountPage from "../pages/Account/AccountPage";
import CompleteAdjustment from "../components/SODRecon/CompleteAdjustment";
import InvestmentPage from "../pages/AdminPages/Investments/InvestmentPage";
import { CreateFundPage } from "../pages/AdminPages/Investments/FundInvestment/CreateFundPage";
import AssetForm from "../pages/AdminPages/Investments/AssetInvestment/AssetForm";
import { AIAssistant } from "../pages/AIAssistant/AIAssistantPage";
// import { AllFundQuery } from "../pages/AllFundQuery/AllFundQuery";
import { AllFundQuery as AllFundQueryOption2 } from "../pages/AllFundQuery/AllFundQueryOption2";
import OrderCompletion from "../pages/AlgoJournal/OrderCompletion";
import { routes } from "../routes";
import AuthLayout from "../components/AuthLayout/AuthLayout";
import { isAdmin } from "../utils/validations";
import HomePage from "../pages/HomePage/HomePage";
import UsersPage from "../pages/AdminPages/Users/Users";
import FundPage from "../pages/AdminPages/Funds/FundPage";
import Preferences from "../pages/AdminPages/Preferences/Preferences";
import SoftwareUpdate from "../pages/AdminPages/SoftwareUpdates/SoftwareUpdate";
import Support from "../pages/AdminPages/Support/Support";
import SODRecon from "../pages/SODRecon";
import AdjustmentPage from "../components/SODRecon/Adjustment/AdjustmentPage";
import AutoSuggestion from "../components/AutoSuggestion";

interface AppRoutesProps {
	decodedToken: any;
	isFundQueryOption1: boolean;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ decodedToken, isFundQueryOption1 }) => {
	return (
		<Routes>
			<Route path={routes.LOGIN} element={<LoginPage />} />
			<Route
				path={routes.HOME}
				element={
					<AuthLayout>
						<Outlet />
					</AuthLayout>
				}
			>
				<Route
					index
					element={isAdmin(decodedToken?.permissions[0].role) ? <UsersPage /> : <Dashboard />}
				/>
				<Route path={routes.USERS} element={<UsersPage />} />
				<Route element={<Dashboard />} />
				<Route path={routes.MARKET_ON_OPEN} element={<HomePage />} />
				<Route path={routes.MARKET} element={<HomePage />} />
				<Route path={routes.MARKET_ON_CLOSE} element={<HomePage />} />
				<Route path={routes.MARKET_FUTURE_DAYS} element={<HomePage />} />
				<Route
					path={`${routes.FUNDSDATA}/:fund_id/:transaction_id/:target_mode/:fund_state/:target_date/:fund_group/:calc_date`}
					element={<FundData />}
				/>
				<Route
					path={routes.ALLFUNDQUERY}
					// element={isFundQueryOption1 ? <AllFundQuery /> : <AllFundQueryOption2 />}
					element={<AllFundQueryOption2 />}
				/>
				<Route
					path={`${routes.ORDERCOMPLETION}/:fund_id/:target_date/:target_mode/:fund_state/:transaction_id/`}
					element={<OrderCompletion />}
				/>
				<Route path={routes.ALLFUNDSTATUS} element={<AllFundStatus />} />
				<Route path={routes.COACMANAGEMENT} element={<CoacManagement />} />
				<Route path={routes.COMING} element={<FundPage />} />
				<Route path={routes.COMINGPREFERENCE} element={<Preferences />} />
				<Route path={routes.COMINGUPDATES} element={<SoftwareUpdate />} />
				<Route path={routes.COMINGSUPPORT} element={<Support />} />
				<Route path={routes.ACCOUNT} element={<AccountPage />} />
				<Route path={`${routes.SODRECON}/:fund_group/`} element={<SODRecon />} />
				<Route path={`${routes.AIASSISTANT}/:symbol`} element={<AIAssistant />} />
				<Route
					path='/:asset_name/:asset_id/:fund_group/:result_type/:fund_id'
					element={<AdjustmentPage />}
				/>
				<Route
					path='/:asset_name/:asset_id/:fund_group/:result_type/:fund_id/update_complete_adjustment'
					element={<AdjustmentPage />}
				/>
				<Route
					path='/:asset_name/:asset_id/:fund_group/:result_type/completed'
					element={<CompleteAdjustment />}
				/>
				<Route
					path='/:asset_name/:asset_id/:fund_group/:result_type/:fund_id/completed'
					element={<CompleteAdjustment />}
				/>
				<Route path={routes.INVESTMENTS} element={<InvestmentPage />} />
				<Route path={`${routes.INVESTMENTS}/${routes.CREATE_ASSETS}`} element={<AssetForm />} />
				<Route
					path={`${routes.INVESTMENTS}/${routes.CREATE_ASSETS}/create-fund/:asset_id`}
					element={<AssetForm />}
				/>
				<Route path={routes.CREATE_FUND} element={<CreateFundPage />} />
				<Route path={routes.AIASSISTANT} element={<AIAssistant />} />
			</Route>
			<Route path={routes.DEMOSTARTER} element={<DemoStarter />} />
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default AppRoutes;
