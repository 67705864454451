export const services = {
	GET_TOKENS: "/auth/token",
	GET_PRESETS: "api/all-funds-query-presets",
	ALGO_JOURNAL: "api/algo-journal",
	ORDER_LIST: "api/order-list",
	FUND_MANAGER_COUNTERS: "api/fund-manager-counters",
	FUND_STATE: "api/funds",
	FUND_ASSETS_AGG: "api/fund-assets-agg",
	WORK_ITEM: "api/work-items",
	GET_FUND_DETAILS: "users/fund-authorization-entries",
	CREATE_USER: "users/",
	UPDATE_DELETE_USER: (userId: string) => `/users/${userId}/roles`,
	ASSETS: "api/holdings",
	ORDERS: "api/orders",
	BENCHMARK: "api/benchmark-changes",
	TARGETS: "api/fund-targets",
	TRADES: "api/trades",
	ORDERCLAIM: "api/order-claim",
	ORDERCLAIMSTATUS: "api/order-claim-status",
	ROLES: "roles/",
	ORDERAPPROVAL: "api/order-approval",
	CASHFLOW: "api/cash-flows",
	GET_FUND_GROUPS: "users/fund-groups",
	DEMO_STARTER: "api/cash-flow-events",
	POST_EVENT: "api/events",
	GET_TEMPLATES: "api/fund-targets-template",
	FUND_HOLDINGS: "api/fund-holdings-recon-work-items",
	FUND_ADJUSTMENT: "api/fund-asset-adjustment-substitutions",
	FIXED_ISSUE: "api/work-items/fixed-issue",
	FUND_ATTRIBUTES: "api/fund-attributes",
	ASSET_INVESTMENT: "api/assets",
	SYSTEM_DATE: "api/system-date",
	AI_ASSISTANT: "api/earnings-call-transcripts/summary",
	ACTIVITY_STATUS: "/api/fund-activity-status",
	CHAT_API: "api/chat",
	CREATE_SESSION: "api/create_session",
	FUND_PERMISSION: "api/fund-permissions",
	ORDER_APPROVAL_STATUS: "api/order-approval-status",
	EARNING_TRANSCRIPT: 'api/earnings-call-transcripts/count',
};
