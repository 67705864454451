import { useEffect, useState } from "react";
import axios from "axios";
import { services } from "../../services";
import { getCurrentUTCDate } from "../dateUtils";
import { RowInfo } from "../../types";
import { calculatePercentage, directPercentage } from "../conversions";
import { useTranslation } from "react-i18next";
import { useFundActivityStatus } from "../useFundActivityStatus";

export const useFunds = (selectedMarketState: string) => {
    const { t } = useTranslation();
    const [fundsData, setFundsData] = useState<RowInfo[]>([]);
    const [fundsError, setFundsError] = useState<string | null>(null);
    const [funds, setFunds] = useState<RowInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { fetchFundActivityStatus } = useFundActivityStatus();
    const [error, setError] = useState<string | null>(null);

    const queryParams = {
        market_state: selectedMarketState,
        all_funds_flag: true,
        target_date: selectedMarketState === "MARKET_ON_CLOSE_PLUS_DAYS" ? selectedMarketState : getCurrentUTCDate(),
        calc_date: getCurrentUTCDate(),
    };

    const formattedParams = Object.fromEntries(
        Object.entries(queryParams).map(([key, value]) => [key, String(value)])
    );

    const fetchDataForTable = async () => {
        try {
            const [res, fundActivityData] = await Promise.all([
                axios.get(`${services.FUND_STATE}?${new URLSearchParams(formattedParams)}`),
                fetchFundActivityStatus(),
            ]);

            setLoading(false);

            if (!res.data || !res.data.data || res.data.data.length === 0) {
                setFunds([]);
                setError(t("NO_DATA_FOUND"));
                setLoading(false);
                return;
            }

            const fundState = (res.data.data[0] as any)?.fund_state || {};
            const stateFlags =
                Object.keys(fundState).filter((key) => key.endsWith("state_flags"))[0] || "";

            const validFundActivityData = Array.isArray(fundActivityData) ? fundActivityData : [];

            const fundActivityMap = new Map(
                validFundActivityData.map((item: any) => [item.fund_id, item.fund_activity_on]),
            );

            const getViolatedTolerance = (violations: any[], type: string) => {
                const target = violations.find((violation: any) => violation.fund_target.type === type);
                return target ? target.violated_tolerance : null;
            };

            const getLowerThreshold = (violations: any[], type: string) => {
                const target = violations.find((violation: any) => violation.fund_target.type === type);
                return target ? target.fund_target.lower_threshold : null;
            };

            const getUpperThreshold = (violations: any[], type: string) => {
                const target = violations.find((violation: any) => violation.fund_target.type === type);
                return target ? target.fund_target.upper_threshold : null;
            };

            const rowIdOrder: { [key: number]: string } = {};

            const tableData = res.data.data
                // .filter((item: any) => item.fund_state[stateFlags])
                .map((item: any, index: number) => {
                    const status = item.is_fund_locked || "false";

                    const progressStatus =
                        fundActivityMap.has(item.fund_id) && fundActivityMap.get(item.fund_id)
                            ? fundActivityMap.get(item.fund_id) === true
                            : false;

                    rowIdOrder[index + 1] = status;
					return {
						id: `${item.fund_id}_${item.transaction_id}`, 
						sr_no: index + 1,
						fund_id: item.fund_id,
						fund_summary_type: item.fund_summary_type,
						fund_name: item.fund_attributes.fund_name,
						tol_start: item.start_of_the_day_status === "TOLERANCE_OUT" ? t("OUT") : t("IN"),
						tol_current: (() => {
							const readyStateFlags =
								item.fund_state?.fund_ready_state_flags ||
								item.fund_state?.fund_completed_state_flags;

							if (readyStateFlags && Object.values(readyStateFlags).some((flag) => flag)) {
								const readyState = Object.keys(readyStateFlags).find((key) => readyStateFlags[key]);

								if (readyState) {
									return readyState.split("_")[0].toUpperCase();
								}
							}
							return null;
						})(),
						order_status: t(item.fund_state?.fund_state),
						primary_fund_manager: item.fund_attributes.primary_fund_manager,
						next_step: "Review orders",
						wip: status,
						spend_cash_bef: calculatePercentage(
							item.fund_stats.spendable_cash,
							item.fund_stats.total_nav,
						),
						spend_cash_aft: calculatePercentage(
							item.new_fund_stats.spendable_cash,
							item.fund_stats.total_nav,
						),
						unequitized_cash_bef: calculatePercentage(
							item.fund_stats.unequitized_cash,
							item.fund_stats.total_nav,
						),
						unequitized_cash_aft: calculatePercentage(
							item.new_fund_stats.unequitized_cash,
							item.fund_stats.total_nav,
						),
						assets: directPercentage(
							item.fund_stats.asset_max_active_weight,
						),
						industry: directPercentage(item.fund_stats.sector_max_active_weight),
						country: directPercentage(item.fund_stats.country_max_active_weight),
						currency: directPercentage(item.fund_stats.currency_max_active_weight),
						size: item.fund_stats.size_max_active_weight,
						shortpos: item.fund_stats.short_positions_assets.length,
						nohold: item.fund_stats.no_hold_assets.length,
						nonbench: item.fund_stats.non_benchmark_assets.length,
						activerisk: item.fund_stats.active_risk,
						equityactiverisk: item.fund_stats.active_risk,
						as_of_date: item.as_of_date,
						transaction_id: item.transaction_id,
						target_date_data_date: item.target_date_data_date,
						is_fund_locked: item.is_fund_locked,
						fund_group: item.fund_attributes.fund_group,
						fund_state: item.fund_state.fund_state,
						total_nav: item.fund_stats.total_nav,
						spend_cash_bef_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						spend_cash_bef_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						spend_cash_bef_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						spend_cash_aft_violated_tolerances: directPercentage(
							getViolatedTolerance(item.new_fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						spend_cash_aft_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.new_fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						spend_cash_aft_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.new_fund_stats_with_target_violation, "SPENDABLE_CASH"),
						),
						unequitized_cash_bef_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						unequitized_cash_bef_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						unequitized_cash_bef_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						///
						unequitized_cash_aft_violated_tolerances: directPercentage(
							getViolatedTolerance(item.new_fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						unequitized_cash_aft_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.new_fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						unequitized_cash_aft_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.new_fund_stats_with_target_violation, "UNEQUITIZED_CASH"),
						),
						//////
						asset_max_active_wgt_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "ASSET_MAX_ACTIVE_WEIGHT"),
						),
						asset_max_active_wgt_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "ASSET_MAX_ACTIVE_WEIGHT"),
						),
						asset_max_active_wgt_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "ASSET_MAX_ACTIVE_WEIGHT"),
						),
						///
						country_max_active_wgt_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "COUNTRY_MAX_ACTIVE_WEIGHT"),
						),
						country_max_active_wgt_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "COUNTRY_MAX_ACTIVE_WEIGHT"),
						),
						country_max_active_wgt_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "COUNTRY_MAX_ACTIVE_WEIGHT"),
						),
						///
						sector_max_active_wgt_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "SECTOR_MAX_ACTIVE_WEIGHT"),
						),
						sector_max_active_wgt_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "SECTOR_MAX_ACTIVE_WEIGHT"),
						),
						sector_max_active_wgt_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "SECTOR_MAX_ACTIVE_WEIGHT"),
						),
						///
						currency_max_active_wgt_violated_tolerances: directPercentage(
							getViolatedTolerance(item.fund_stats_with_target_violation, "CURRENCY_MAX_ACTIVE_WEIGHT"),
						),
						currency_max_active_wgt_violated_tolerances_lower: directPercentage(
							getLowerThreshold(item.fund_stats_with_target_violation, "CURRENCY_MAX_ACTIVE_WEIGHT"),
						),
						currency_max_active_wgt_violated_tolerances_upper: directPercentage(
							getUpperThreshold(item.fund_stats_with_target_violation, "CURRENCY_MAX_ACTIVE_WEIGHT"),
						),
						///
						progress_status: progressStatus || false,
					};
                });

            setFundsData(tableData);
            setFundsError(null);

            if (tableData.length > 0) {
                localStorage.setItem("FundLocked", JSON.stringify(rowIdOrder));
                setFunds((prevFunds) => {
                    if (JSON.stringify(prevFunds) === JSON.stringify(tableData)) {
                        return prevFunds;
                    }
                    return tableData;
                });
                setError(null);
            } else {
                setFunds([]);
                setError(t("NO_DATA_FOUND"));
            }
        } catch (error: any) {
            console.error("Error making API request:", error);
            setError(error?.response?.status || t("UNKNOWN_ERROR"));
        }
    };

    useEffect(() => {
        fetchDataForTable();
    }, [selectedMarketState]);

    return { fundsData, fundsError, fetchDataForTable };
};
