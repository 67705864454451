import React, { useEffect, useState, useCallback, PropsWithChildren } from "react";

interface InactivityWrapperProps {
	onTimeout: () => void;
	timeout: number;
}

const InactivityWrapper: React.FC<PropsWithChildren<InactivityWrapperProps>> = ({
	onTimeout,
	timeout,
	children,
}) => {
	const [lastActive, setLastActive] = useState(Date.now());

	const resetTimer = useCallback(() => {
		// console.log("Activity detected. Resetting timer."); 
		setLastActive(Date.now());
	}, []);

	useEffect(() => {
		const handleActivity = () => {
			// console.log("User activity detected."); 
			resetTimer();
		};

		const events = ["mousemove", "keydown", "mousedown", "touchstart", "scroll", "focus"];
		events.forEach((event) => window.addEventListener(event, handleActivity));

		return () => {
			events.forEach((event) => window.removeEventListener(event, handleActivity));
			console.log("Event listeners removed."); 
		};
	}, [resetTimer]);

	useEffect(() => {
		const interval = setInterval(() => {
			const timeSinceLastActive = Date.now() - lastActive;
			// console.log("Time since last activity:", timeSinceLastActive); 
			if (timeSinceLastActive > timeout) {
				console.log("Timeout reached. Logging out now."); 
				onTimeout();
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [lastActive, timeout, onTimeout]);

	useEffect(() => {
		// console.log("Last active updated:", lastActive); 
	}, [lastActive]);

	return <>{children}</>;
};

export default InactivityWrapper;
