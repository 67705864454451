import { GridOptions } from "ag-grid-community";
import { routes } from "../../routes";
import { t } from "i18next";

export const gridOptions: GridOptions = {
    suppressRowHoverHighlight: true,
};

export const fundIdHeader = t("FUND_ID");
export const CANCEL = t("CANCEL")
export const I_M_STARTING = t("I_M_STARTING");
export const ME = t("ME");

export const disabledStatesForNavigation = {
    [ME]: 'false',
    [I_M_STARTING]: 'true',
};

export const rowHeight = 30;
export const rowHeightLarge = 40;
export const rowHeightRem = 1.875;
export const rowHeightLargeRem = 2.5;
export const rowSelectionTypeSingle = 'single';
export const rowSelectionTypeMultiple = 'multiple';
export const scrollHeight = 0.7;

export const customHeight = (rowData: any[] = [], headerHeight?: number, rowHeight?: number) => {
  const baseHeaderHeight = headerHeight ?? 3.2; 
  const baseRowHeight = rowHeight ?? 2.5
  if (rowData.length > 0) {
      const heightInRem = baseHeaderHeight + (rowData.length * baseRowHeight) + scrollHeight;
      return `${heightInRem}rem`;
  }
  else {
      return `${baseHeaderHeight + 2.48}rem`; 
  }
};

export const getCellClassForSpendCash = (params: any, field: string): string => {
    const value = parseFloat(params.data[field]);
    const tolerance = params.data[`${field}_violated_tolerances`];
    const toleranceLower = params.data[`${field}_violated_tolerances_lower`];
    const toleranceUpper = params.data[`${field}_violated_tolerances_upper`];
    
    if (tolerance && tolerance !== "0.00%") {
      if (value >= toleranceLower && value <= toleranceUpper) {
      return 'spendCashCellNone'; 
      } else {
      return 'spendCashCell';
      }
    }
    return ''; 
    };

    export const defaultColDef={
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 100,
        flex: 1
      }


  



