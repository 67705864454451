import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextInput } from '@mantine/core';
import { t } from 'i18next';
import { InputError } from './Common/Errors/InputError';

interface AutocompleteProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  className?: string;
}

const currencyField = ['CURRENCY', 'FUND_CURRENCY'];
const countryField = ['COUNTRY', 'ISSUE_COUNTRY', 'TRADING_COUNTRY', 'RISK_COUNTRY'];

export const AutoSuggestion: React.FC<AutocompleteProps> = ({
  label,
  value,
  onChange,
  onFocus,
  errorMessage,
  className,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [hint, setHint] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get('/api/countries');
        const { countries, currencies } = response.data.data as {
          countries: { country?: string; currency?: string }[];
          currencies: { currency: string }[];
        };

        if (currencyField.includes(label)) {
          const uniqueCurrencies = Array.from(new Set(currencies.map((c) => c.currency))).sort();
          setOptions(uniqueCurrencies);
        } else if (countryField.includes(label)) {
          const uniqueCountries = Array.from(
            new Set(countries.map((c) => c.country).filter((value): value is string => !!value))
          ).sort();
          setOptions(uniqueCountries);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [label]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    onChange(inputValue);

    const matches = options.filter((option) =>
      option.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    setFilteredOptions(matches);
    setHint(matches.length > 0 ? matches[0] : '');
    setActiveOptionIndex(-1);
    setShowDropdown(matches.length > 0);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setFilteredOptions([]);
    setHint('');
    setShowDropdown(false);
    setActiveOptionIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && hint) {
      e.preventDefault();
      onChange(hint);
      setHint('');
      setShowDropdown(false);
    } else if (e.key === 'ArrowDown') {
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
        setHint(filteredOptions[nextIndex]);
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setActiveOptionIndex((prevIndex) => {
        const prevIndexValue = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
        setHint(filteredOptions[prevIndexValue]);
        return prevIndexValue;
      });
    } else if (e.key === 'Enter' && activeOptionIndex !== -1) {
      handleOptionClick(filteredOptions[activeOptionIndex]);
    }
  };

  return (
    <div style={{ position: 'relative', width: '300px' }}>
      <div style={{ position: 'relative' }}>
        <TextInput
          type="text"
          label={t(label)}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            setShowDropdown(filteredOptions.length > 0);
            onFocus && onFocus(e)
          }
           
          }
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          className={className}
        />
        {hint && value && (
          <span className="hint-input">
            {hint}
          </span>
        )}
      </div>

      {showDropdown && (
        <ul className="hint-options">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className="option-color"
              style={{
                backgroundColor:
                  index === activeOptionIndex
                    ? '#e6f7ff'
                    : index % 2 === 0
                      ? '#f9f9f9'
                      : '#fff',
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      {errorMessage && <InputError message={errorMessage} />}
    </div>
  );
};

export default AutoSuggestion;
