import React, { useState, useRef } from "react";
import { ColDef, GridOptions, RowDoubleClickedEvent } from "ag-grid-community";
import { useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import "../Agtablehomepage/Agstyles.css";
import { ActionItemsTableProps } from "../../types";
import { routes } from "../../routes";
import { WORK_ITEM_NAVIGATION } from "../../constants";
import { customHeight, rowHeight, rowSelectionTypeMultiple } from "../CustomTable/utilsAgTable";
import CustomTable from "../CustomTable/CustomTable";
import { getTableData } from "../../utils/conversions";
import { AgGridReact } from "ag-grid-react";

const ActionItemsTable: React.FC<ActionItemsTableProps> = ({
    setSelectedRows,
    rowData,
    updateSelectedRowCount,
    onSelectionChange,
    onCommentsChange,
}) => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const gridApiRef = useRef<any>(null);

    const commonCellStyle = {
        fontWeight: "400",
        fontSize: theme.fontSizes.md,
    };

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(() => {
        const hasDueDate = rowData.some(item => item.due_date);
        const tableData = getTableData("DASHBOARD");
        const dynamicColumnDefs: ColDef[] = [
             ...tableData.map(({ colHeader, key, decorators }) => ({
                    headerName: t(colHeader),
                    field: key,
                    cellRenderer: null,
                    flex: 1
                  })),
        ];

        if (hasDueDate) {
            dynamicColumnDefs.unshift({
                headerName: t("DUE_DATE"),
                field: "due_date",
                width: 150,
            });
        }

        return dynamicColumnDefs;
    });

    const defaultColdef: ColDef = {
        headerClass: "custom-header-dashboard",
        cellStyle: commonCellStyle
    };

    const gridOptions: GridOptions = {
        rowData: rowData,
        suppressRowHoverHighlight: true,
        onSelectionChanged: () => {
            if (gridApiRef.current) {
                const selectedNodes = gridApiRef.current.getSelectedNodes();
                const selectedData = selectedNodes.map((node: { data: []; }) => node.data);
                setSelectedRows(selectedData);
                const ids = selectedNodes.map((node: { data: { id: string; }; }) => node.data.id);
                const comments = selectedNodes.map((node: { data: { comments: string; }; }) => node.data.comments);
                onCommentsChange(comments);
                onSelectionChange(ids);
                updateSelectedRowCount(selectedData.length);
            }
        },
    };

    const onGridReady = (params: { api: { getSelectedNodes: () => any; }; }) => {
        console.log("This onGridReady")
        gridApiRef.current = params.api;
    };

    const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {

        const { fund_group, symbol, alert_category } = event.data;
        if (alert_category === WORK_ITEM_NAVIGATION.SOD_COMPLETED) {
            navigate(`/sod-recon/${fund_group}`);
        }
        if (alert_category === WORK_ITEM_NAVIGATION.AI_CHAT) {
            navigate(`${routes.AIASSISTANT}/${symbol}`);
        }
    };

    return (
        <>
            <CustomTable rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady} rowHeight={rowHeight}
                defaultColDef={defaultColdef} onRowDoubleClicked={handleRowDoubleClick} className="action-item" emptyMsg='NO_WORK_ITEMS_TO_SHOW'
                gridOptionsProp={gridOptions}/>
        </>
    );
};

export default ActionItemsTable;